import { gql } from "graphql-request"

// export const CREATE_CONTACT = gql`
//   mutation CreateContact($input: createQuestionInput!) {
//     createQuestion(input: $input) {
//       question {
//         id
//       }
//     }
//   }
// `

export const CREATE_SURVEY_DATA = gql`
  mutation CreateSurveyData($input: createSurveydatumInput!) {
    createSurveydatum(input: $input) {
      surveydatum {
        id
      }
    }
  }
`

export const CREATE_SUBSCRIBER = gql`
  mutation CreateSubscriber($input: createSubscriberInput!) {
    createSubscriber(input: $input) {
      subscriber {
        id
      }
    }
  }
`

// STRAPI LEAD MUTATIONS
// export const CREATE_LEAD = gql`
//   mutation CreateLead($input: createLeadCourseInput!) {
//     createLeadCourse(input: $input) {
//       leadCourse {
//         id
//       }
//     }
//   }
// `
// export const UPDATE_LEAD = gql`
//   mutation UpdateLead($input: updateLeadCourseInput!) {
//     updateLeadCourse(input: $input) {
//       leadCourse {
//         id
//         isPayment
//         freeLesson
//       }
//     }
//   }
// `

// MUTATION(AWS)
export const CREATE_USER_LEAD = `
 mutation createUserLead($input: CreateUserLeadInput!) {
   createUserLead(input: $input)
 }
`
// export const CREATE_USER_LEAD = `
// mutation createUser($input: CreateUserInput!) {
//   createUser(input: $input)
// }
// `
export const GET_STATUS_OF_PAYMENT = `
mutation getStatusOfPayment($input: StatusOfPayment!){
  getStatusOfPayment(input:$input)
}`
// export const GET_STATUS_OF_PAYMENT = `
// mutation getStatsPay($input: StatsPay!){
//   getStatsPay(input:$input)
// }`
export const UPDATE_USER_STEPS = `
mutation updateUserSteps($input: UpdateUserStepsInput!) {
  updateUserSteps(input: $input)
}
`

export const SET_USER_FEEDBACK = `
  mutation setUserFeedback($input: UserFeedbackInput!) {
  setUserFeedback(input: $input)
}
`

// CREATE QUESTION TO CONTACT US
export const CREATE_QUESTION = `
  mutation createQuestion($input: CreateQuestionInput!) {
    createQuestion(input: $input)
  }
`

// Verify if code is valid and get access
export const GET_PRESENTATION_ACCESS = `
 mutation getPresentationAccess($input: PresentationAccessInput!) {
   getPresentationAccess(input: $input)
 }
`

export const RECORD_INVESTOR_FEEDBACK = `
 mutation recordInvestorFeedback($input: InvestorFeedbackInput!) {
   recordInvestorFeedback(input: $input)
 }
`
